<template>
	<div class="container">
		<div class="top">
			<div class="ids">
				<div class="swiperWrap" v-if="recordsList.length > 0">
					<swiper :options="swiperOption" class="gallery-top" ref="mySwiper" @slideChangeTransitionEnd="setCurrIndex">
						<swiper-slide v-for="(item, index) in recordsList" :key="index">
							<div class="swiper-zoom-container">
								<img :src="item.thumb" class="itemImg" :id="'thumb' + index" />
							</div>
						</swiper-slide>
					</swiper>
				</div>
				
					<div class="position:absolute;">
						<div class="swiper-pagination swiper-pagination-bullets" ref="navList" id="navList" slot="pagination">			</div>
						<div   @click="prev"  style="background: rgba(0,0,0,0.4);z-index:1;width:36px;height:84px;position: relative;top:-98px;left:5px;cursor: pointer;">
							<img  style="width:40px;height:84px;" src="@/assets/images/pre.png" alt="">
						</div>
						<div @click="next" style="background: rgba(0,0,0,0.4);z-index:1;width:36px;height:84px;position: relative;top:-182px;left:524px;cursor: pointer;">
							<img  style="width:40px;height:84px;" src="@/assets/images/nex.png" alt="">
						</div>
					</div>
	

			</div>
			<div class="rightbox">
				<div class="titlebox">
					<div class="title">{{ detail.title }}</div>
					<div class="btn" @click="apply(detail)">
							{{ detail.data_apply_examine_text }}
					</div>

				</div>
				<div class="location">
					<img style="width:20px;height:20px;" src="/pic/wz.png" alt="">
					<div>{{ detail.ss }}</div>
				</div>
				<div class="renzheng">
					<div class="itemlist">
						<div class="single">
							<div class="left">项目编号：</div>
							<div class="right">{{ detail.number }}</div>
						</div>
						<div class="single">
							<div class="left">用地面积：</div>
							<div class="right">{{ detail.extent_value }} m²</div>
						</div>
						<div class="single">
							<div class="left">项目性质：</div>
							<div class="right">{{ detail.attribute_data }}</div>
						</div>
						<div class="single">
							<div class="left">使用年限：</div>
							<div class="right">{{ detail.life_data }}</div>
						</div>
						<div class="single">
							<div class="left">交易方式：</div>
							<div class="right">{{ detail.trade_data }}</div>
						</div>
						<div class="single">
							<div class="left">交易报价：</div>
							<div class="right">{{ detail.offer }}</div>
						</div>
						
					</div>


					<img class="renzhengtu" src="/pic/tprz.png" alt="">


				</div>
				<div class="tbox">
					<img class="telimg" src="/pic/bgrx.png" alt="">
					<div class="textphon">
						<div class="text">并购中心热线</div>
						<div class="phone">{{ detail.bingo_phone }}</div>
					</div>
				</div>

			</div>
		</div>
		<div class="con" v-html="detail.details">

		</div>

	</div>
</template>

<script>
import { detail,apply } from "@/api/website/bingo"
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import store from '@/store'

export default {
	components: {
		Swiper,
		SwiperSlide
	},
	directives: {
		swiper: directive
	},

	data() {
		return {
			detail: {},
			swiperOption: {

				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},

				pagination: {
					el: '.swiper-pagination',
					spaceBetween: 10,
					clickable: true,
					renderBullet: (index, className) => {
						this.activeIndex = index
						// console.log('index:'+index)
						return `<span id=a${index} class="${className} swiper-pagination-bullet-custom">
            <img src="${this.recordsList[index].thumb}" style="width:126px;height:82px" class="iconImg" alt="0" />
            <em class="redPoint${index}"></em>
            </span>`
					}
				}
			},
			recordsList: []


		};
	},
	beforeMount() {
		let timer1 = setInterval(() => {
			let rootFontSize = (document.documentElement.clientWidth || document.body.clientWidth) / 10;
			document.getElementsByTagName("html")[0].style.fontSize = rootFontSize + "px";
			if (rootFontSize > 32) {
				clearInterval(timer1);
			}
		}, 60);
		let timer2 = setTimeout(() => {
			clearInterval(timer1);
			clearTimeout(timer2);
		}, 3000);
	},


	computed: {
		swiper() {
			return this.$refs.mySwiper.$swiper
		}
	},

	mounted() {
		this.$nextTick(() => {
			const swiperTop = this.$refs.mySwiper.$el.swiper;
			const swiperThumbs = this.$refs.mySwiperthumbs.$el.swiper;
			swiperTop.controller.control = swiperThumbs;
			swiperThumbs.controller.control = swiperTop;
		})

		this.init()
	},

	methods: {

		apply(data) {

		var that = this
		if (store.state.user.token == null) {
			// const tourl = '/' + this.site.path + '/list/jjrzc'
			window.location.href = process.env.VUE_APP_LOGIN_URL + '?url=' + window.btoa(unescape(encodeURIComponent(window.location)))

			// this.$router.push(tourl)
		} else {

			const params = { id: data.id }


			if (data.data_apply_examine === 1) {
				this.downloadZip(data)
			} else {
				apply(params).then(res => {
					if (res.code === 1) {
						// if (data.data_apply_examine === 0) {
						that.init()
						// }
					}
				})
			}
		}


		},


		
		startLoading() {
			this.Loading = this.$loading({
				spinner: "",//自定义加载图标类名
				text: "数据正在打包中...",//显示在加载图标下方的加载文案
				background: "rgba(0, 0, 0, 0.3)",//遮罩背景色
			})
		},


		downloadZip(data) {
			this.startLoading()
			const params = {
				file_ids: data.data
			}
			downloadZip(params).then(res => {
				window.location.href = res.data
				this.Loading.close();
			})
		},


		prev() {
			
			// console.log('this.$refs.mySwiper.$el.swiper',this.$refs.mySwiper.$el.swiper)
			if( this.$refs.mySwiper.$el.swiper.activeIndex > 2){
				const num = this.$refs.mySwiper.$el.swiper.activeIndex - 2
				let container = this.$refs.navList
     	 	container.scrollLeft = 142*num
			}
      this.$refs.mySwiper.$el.swiper.slidePrev()
    },

    next() {
			if( this.$refs.mySwiper.$el.swiper.activeIndex > 2){
			const container = this.$refs.navList
			const num = this.$refs.mySwiper.$el.swiper.activeIndex
     	container.scrollLeft = 142*num 	
		}
      this.$refs.mySwiper.$el.swiper.slideNext()
    },

		setCurrIndex() {
			this.activeIndex = this.$refs.mySwiper.swiper.activeIndex;
		},

		init() {

			detail({ id: this.$route.params.id }).then(res => {
				this.detail = res.data[0]
				
				this.recordsList = res.data[0].cover
			})

		}
	},
};
</script>

<style lang="scss" scoped>
// 缩略图 icon 样式，我是将分页器样式覆盖改成缩略图的
// .swiper-button-prev{
// 	background:red;
// 	z-index:1000;
// 	bottom:50px;
// 	position: absolute;
// }
// .swiper-button-next{
// 	background:green;
// 	z-index:1000;
// 	bottom:50px;
// 	position: absolute;
// }
.tbox {
	width: 574px;
	height: 84px;
	background: #F0F3F8;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-top: 26px;

	.telimg {
		width: 64px;
		height: 64px;
		margin-left: 20px;
		margin-right: 20px;
		background: #E77817;

	}

	.textphon {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		.text {
			width: 192px;
			height: 30px;
			font-size: 22px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #E77817;
			line-height: 30px;
			letter-spacing: 10px;
		}

		.phone {
			// width: 194px;
			height: 30px;
			font-size: 26px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
			color: #E77817;
			line-height: 30px;
		}
	}
}


.top {
	width: 1200px;
	height: 504px;
	background: #FFFFFF;
	display: flex;

	.ids {
		background: #fff;
		margin: 20px;
		width: 566px;
		height: 464px;


	}

	.rightbox {
		width: 574px;
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		justify-content: flex-start;
		align-items: flex-start;

		.titlebox{
			display: flex;
			justify-content: space-between;
			width:100%;
		.title {
			text-align: left;
			padding-right:20px;
			box-sizing: border-box;
			width: 100%;
			height: 28px;
			font-size: 20px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 28px;
		}
		.btn {
			margin-right:20px;
			white-space: nowrap;
						// width: 144px;
						display: inline-block;
						padding: 0 15px;
						height: 26px;
						line-height: 26px;
						background: #FFFFFF;
						border: 1px solid #00A3E4;
						cursor: pointer;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #00A3E4;


					}

				}

		.renzheng {
			width: 574px;
			height: 262px;
			background: #F0F3F8;
			padding: 10px 20px;
			box-sizing: border-box;
			display: flex;


			.itemlist {
				width: 440px;
				padding-top:10px;

				.single {
					height: 40px;
					display: flex;
					justify-content: flex-start;
					text-align: left;


					.left {
						color: #999;
						width: 80px;
						font-size: 16px;
					}

					.right {
						color: #000;
						width: 360px;
						font-size: 16px;
					}
				}

			}

			.renzhengtu {
				height: 100px;
				width: 100px;
				margin-top: 10px;
			}
		}

		.location {
			display: flex;
			flex-direction: row;
			height: 60px;
			justify-content: flex-start;
			align-items: center;

			div {
				margin-left: 10px;
				font-size: 14px;
			}
		}

	}
}

.con {
	margin-top: 20px;
	width: 1200px;
	min-height: 508px;
	background: #FFFFFF;
	padding: 20px;
	box-sizing: border-box;
}



::-webkit-scrollbar {
	width: 0;
	height: 0
}

::v-deep .swiper-pagination {
	width: 556px;
	height: 100%;
	box-sizing: border-box;
	padding: 10px 0px;
	overflow-x: scroll;
	white-space: nowrap;
	position: static !important;
	
	overflow-y: hidden;
	margin-left: 6px;
	margin-top:16px;
}


::v-deep .swiper-pagination-bullet {
	width: 128px !important;
	height: 84px !important;
	border-radius: 1px !important;
	background: #d0d0d0 !important;
	border: 1px solid transparent;
	box-sizing: border-box;
	opacity: 1 !important;
	margin-right: 14px;
	position: relative;
	font-size: 0;
}

::v-deep .swiper-pagination-bullet:last-child{
	margin-right: 0;
}


::v-deep .swiper-pagination-bullet-active {
	width: 128px !important;
	height: 84px !important;
	background: #fff !important;
	border: 1px solid #5c46ff;
	box-sizing: border-box;
}


::v-deep .swiper-pagination-bullet:focus {
	outline: none !important;
}


::v-deep .swiper-pagination-bullet-active:focus {
	outline: none !important;
}


::v-deep .swiper-pagination-bullet:active {
	outline: none !important;
}


::v-deep .swiper-pagination-bullet-active:active {
	outline: none !important;
}


::v-deep .iconImg {
	width: 106px;
	height: 84px;
	border-radius: 2px;
}
.itemImg{

	width: 556px;
	height: 350px;
	background-size: contain;
	object-fit:cover;
}


.container {
	width: 1200px;
	margin: 0 auto;
	background: #F0F3F8;
}




</style>